import React from 'react';
import styles from './TermsAndCondition.module.scss';

const TermsAndConditionsPage = () => {
  return (
    <div className={'transferModal'}>
      <div className={styles.termsModal}>
        <h2 className={styles.heading}>利用規約</h2>
        <p className={styles.date}>改定日　2024年10月10日</p>
        <p>
          この利用規約（以下、「本規約」といいます。）は、ガンプネットワークス株式会社（以下、「当社」といいます。）が、このウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザー（以下、「ユーザー」といいます。）は、本規約に従って、本サービスをご利用いただきます。
        </p>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第1条　適用</h3>
          <p>
            本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </p>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第2条　ユーザー登録</h3>
          <ol>
            <li>
              本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこの承認を登録希望者に通知することによって、利用登録が完了するものとします。
            </li>
            <li>
              当社は、ユーザー登録の申請者が、過去に第5条（禁止事項）に該当したと判断する場合、利用登録の申請を承認しないことがあります。その理由については一切の開示義務を負わないものとします。
            </li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>
            第3条　ユーザーIDおよびパスワードの管理
          </h3>
          <ol>
            <li>
              ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。
            </li>
            <li>
              ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。
            </li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>
            第4条　コンテンツの権利および管理
          </h3>
          <ol>
            <li>
              ユーザーが本サービスに投稿した文書等の電子データ（以下、「コンテンツ」といいます。）の権利は、コンテンツを投稿したユーザーに帰属します。
            </li>
            <li>
              ただし、以下の場合には、当社はユーザーに通知することなく、コンテンツを閲覧、複製、削除する場合があります。
            </li>
          </ol>
          <ol className={styles.numberedList}>
            <li>当社が第5条（禁止事項）に該当すると判断した場合</li>
            <li>当社が本サービスの運営維持のため必要があると判断した場合</li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第5条　禁止事項</h3>
          <p>
            ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
          </p>
          <ol className={styles.numberedList}>
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>著作権、商標権等の知的財産権を侵害する行為</li>
            <li>
              当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
            </li>
            <li>当社のサービスの運営を妨害するおそれのある行為</li>
            <li>
              他のユーザーにコンピューターウィルスまたはスパム広告を配信する行為
            </li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>
              他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
            </li>
            <li>反社会的勢力に対して直接または間接に利益を供与する行為</li>
            <li>その他、当社が不適切と判断する行為</li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第6条　利用料金および支払方法</h3>
          <ol>
            <li>
              ユーザーは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします。
            </li>
            <li>
              ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
            </li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>
            第7条　本サービスの保守および停止等{' '}
          </h3>
          <ol>
            <li>
              当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
            </li>
          </ol>
          <ol className={styles.numberedList}>
            <li>
              本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            </li>
            <li>災害、停電、事故により、本サービスの提供が困難となった場合</li>
            <li>犯罪行為によって本サービスの提供が困難となった場合</li>
            <li>行政の命令または指導があった場合</li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第8条　利用制限および登録抹消</h3>
          <ol>
            <li>
              当社は、以下の場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
            </li>
          </ol>
          <ol>
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>当社が本サービスの利用を適当でないと判断した場合</li>
            <li>
              当社は、本条に基づき当社が行った行為により、ユーザーに生じた損害について、一切の責任を負いません。
            </li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第9条　退会</h3>
          <p>
            ユーザーは、当社の定める退会手続により、本サービスから退会できるものとします。
          </p>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第10条　免責事項</h3>
          <ol>
            <li>
              当社は、本サービスに起因してユーザーに生じたあらゆる損害について、一切の責任を負いません。ただし、有料サービスにおいては、当社の過失または不法行為によって生じた損害について、有料サービス利用代金の金額を上限として賠償の責任を負うものとします。
            </li>
            <li>
              当社は、第7条（本サービスの保守およぼ停止等）に該当する処置によってユーザーに生じた損害について一切の責任を負いません。
            </li>
            <li>
              当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切の責任を負いません。
            </li>
          </ol>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第11条　利用規約の変更</h3>
          <p>当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。</p>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第12条　通知または連絡</h3>
          <p>ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。</p>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第13条　権利義務の譲渡の禁止</h3>
          <p>ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。</p>
        </section>

        <section className={styles.termsSection}>
          <h3 className={styles.subheading}>第14条　準拠法・裁判管轄</h3>
          <p>本規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</p>
        </section>

        <section>
          <p>以上</p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
