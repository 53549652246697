import React, { FC } from 'react';
import { Button } from '@willeder/component-library';
import useModalStore from 'store/useModalStore';
import { useTranslation } from 'react-i18next';
import { ReceiverLinkModalProps } from 'types/modals';
import useAlert from 'store/useAlert';
import styles from './ReceiverLinkModal.module.scss';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';

const ReceiverLinkModal: FC<ReceiverLinkModalProps> = ({
  generatedCryptoLink,
  isRegenerated = false,
  isNewReceiver = false,
  isNewSpace = false,
}) => {
  const { t } = useTranslation();
  const { closeModal, setOpenModal } = useModalStore();
  const setAlert = useAlert((state) => state.setAlert);
  const isDisplaySpaceReadme = localStorage.getItem('isDisplaySpaceReadme');

  const onCloseModal = () => {
    if (isRegenerated || isDisplaySpaceReadme) closeModal();
    else if (isNewSpace && !isDisplaySpaceReadme) setOpenModal('spaceReadme');
    else closeModal();
  };

  return (
    <div className={styles.confirmModal}>
      {isNewReceiver && (
        <>
          <h1 className={styles.title}>
            {t(
              isRegenerated
                ? 'titles.regeneratedLink'
                : 'titles.spaceCryptoLink'
            )}
          </h1>
          <div className={styles.caution}>
            {/* <h2>{t('labels.caution')}</h2> */}
            <p className={styles.cautionMessageText}>{t('labels.sendLinkToSinglePerson')}</p>
          </div>
        </>
      )}
      <div className={styles.crypto}>
        <h2>{t('labels.cryptoLink')}</h2>
        <p className={styles.linkText}>{generatedCryptoLink}</p>
      </div>
      {isNewReceiver && (
        <p className={styles.description}>
          {t('labels.connectionLinkIssued')}
        </p>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          className="update"
          onClick={() => {
            navigator.clipboard.writeText(generatedCryptoLink);
            const alertMessage = isNewReceiver
              ? t('labels.copiedSharedLinkToast')
              : t('labels.copiedLinkWaitingTooltip');

            setAlert(alertMessage, 'info');
          }}
        >
          <div className={styles.buttonIconWrapper}>
            <CopyIcon height={24} width={24} />
          </div>
          {t('buttons.copyLink')}
        </Button>
        <Button className="close" onClick={onCloseModal}>
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
};

export default ReceiverLinkModal;
