export const getErrorMessage = (code: string) => {
  const errors: ErrorMessages = {
    '1001': 'errors.emailAlreadyInUse',
    '1002': 'errors.emailNotExist',
    '1003': 'errors.OTPNotMatched',
    '1004': 'errors.passwordNotMatch',
    '1005': 'errors.invalidOTP',
    '1006': 'errors.userDeleted',
    '1007': 'errors.userSuspended',
    '1008': 'errors.invalidRefreshToken',
    '1009': 'errors.invalidOTP',
    '1015': 'errors.invalidCVVNumber',
    '1016': 'errors.invalidCardExpiry',
    '1017': 'errors.invalidCardNumber',
    '1018': 'errors.invalidSecretCode',
    '1019': 'errors.invalidToken',
    '1020': 'errors.tooManyAttempts',
    '1021': 'errors.OTPExpired',
    '1022': 'errors.googleOAuthError',
    '1023': 'errors.invalidData',
    '1024': 'errors.invalidOTP',
    '1025': 'errors.alreadySentOTP',
    '1039': 'errors.invalidReceiverSpace',
    '1040': 'errors.receiverSpaceSendNotification',
    '1042': 'errors.receiverSpaceRequestSuspended',
    '1043': 'errors.cannotAccessSpace',
    '1044': 'errors.receiverSpaceAlreadySentRequest',
    '1050': 'errors.planDeleteAccessDeclined',
    '1052': 'errors.secretAuthAlreadyUpdated',
    '1055': 'errors.receiverLinkExpired',
    '1057': 'errors.cannotAccessOwnedSpace',
    '1062': 'errors.maxCreditCardsLimitReached',
    '1063': 'errors.minCreditCardsLimitNotReached',
    '1064': 'errors.planAlreadyExpired',
    '1069': 'errors.tooManySecretCodeAttempts',
    '1070': 'errors.maxSpaceLimit',
    '1071': 'errors.maxReceiverLimit',
    '1076': 'errors.guestJoinedSameSpace',
    '2001': 'errors.invalidToken',
    '2005': 'errors.dataNotFound',
    '2006': 'errors.fieldNameMissingOrEmpty',
    '2084': 'errors.receiverNameDuplicated',

    // CLIENT ERROR MESSAGES
    '3001': 'errors.passwordRequired',
    '3002': 'errors.invalidPassword',
    '3003': 'errors.emailRequired',
    '3004': 'errors.invalidEmail',
    '3005': 'errors.confirmEmailNotMatch',
    '3006': 'errors.confirmPasswordNotMatch',
    '3007': 'errors.nameRequired',
    '3008': 'errors.acceptTerms',
    '3009': 'errors.requiredField',
    default: 'errors.unknownError',
  };
  return errors[code] || errors['default'];
};

export const getSuccessMessage = (code: string) => {
  const successMessage: ErrorMessages = {
    '8001': 'success.emailSent',
    '8002': 'success.passwordChanged',
    '8003': 'success.registerCompleted',
    '8004': 'success.accountResetSuccess',
    '8005': 'success.passwordReset',
    '8006': 'success.accountDeleted',
    default: 'success.success',
  };
  return successMessage[code] || successMessage['default'];
};

export const getFileCategoryByDate = (createdAt: string): string => {
  const currentDate = new Date();
  const fileDate = new Date(createdAt);

  if (
    fileDate.getFullYear() === currentDate.getFullYear() &&
    fileDate.getMonth() === currentDate.getMonth() &&
    fileDate.getDate() === currentDate.getDate()
  ) {
    return 'Today';
  }

  currentDate.setDate(currentDate.getDate() - 1);
  if (
    fileDate.getFullYear() === currentDate.getFullYear() &&
    fileDate.getMonth() === currentDate.getMonth() &&
    fileDate.getDate() === currentDate.getDate()
  ) {
    return 'Yesterday';
  }

  currentDate.setDate(currentDate.getDate() - 6);
  if (fileDate >= currentDate) {
    return 'Last Week';
  }

  currentDate.setMonth(currentDate.getMonth() - 1);
  if (fileDate >= currentDate) {
    return 'Last Month';
  }

  currentDate.setFullYear(currentDate.getFullYear() - 1);
  if (fileDate >= currentDate) {
    return 'Last Year';
  }

  return 'Other';
};

export function bytesToSize(bytes: number) {
  const sizes = ['B', 'KB', 'MB', 'GB'];
  if (bytes <= 0) return '0 KB';
  
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.floor(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}


export function prepareAccordionFileData(
  data: FileData[],
  language: 'en' | 'ja' = 'ja'
) {
  const accordionData: Array<any> = [];
  const parentMap = new Map<string, any>();
  data.forEach((item) => {
    const {
      bulkId,
      fileId,
      fileName,
      filePath,
      fileSize,
      status,
      activeAt,
      createdAt,
    } = item;

    const fileData = {
      id: bulkId,
      bulkId: bulkId,
      title: timeAgo(createdAt, language),
      status: status ? 'labels.off' : 'labels.on',
      activatedAt: convertTimestamp(activeAt),
      fileSize: 0,
      filePath: filePath,
      createdAt: activeAt,
      children: [],
    };

    if (!parentMap.has(bulkId)) {
      parentMap.set(bulkId, fileData);
    }

    parentMap.get(bulkId).children.push({
      id: fileId,
      bulkId: bulkId,
      title: fileName,
      filePath: filePath,
      fileSize: fileSize,
      status: status ? 'labels.off' : 'labels.on',
      activatedAt: convertTimestamp(activeAt),
      createdAt: activeAt,
    });

    parentMap.get(bulkId).fileSize += fileSize;
  });

  parentMap.forEach((value) => {
    if (value.children.length === 1) {
      accordionData.push(value.children[0]);
    } else {
      accordionData.push(value);
    }
  });

  return accordionData;
}

export function convertTimestamp(timestamp: string, isDate: boolean = false) {
  const date = new Date(timestamp);

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return isDate
    ? `${year}/${month}/${day}`
    : `${year}/${month}/${day} ${hours}:${minutes}`;
}

export const getTotalFileSize = (fileList: AccordionItem[]) => {
  const totalFileSize =
    fileList?.reduce((acc, fileData) => acc + fileData.fileSize, 0) || 0;
  return totalFileSize;
};

export const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec));

export const checkForExistingSenderKey = (receiverId: string) => {
  const senderKeyCollection = localStorage.getItem('senderKeyCollection');
  if (senderKeyCollection) {
    const senderKeys: { receiverId: string; senderKey: string }[] =
      JSON.parse(senderKeyCollection);
    const data = senderKeys.find((data) => data.receiverId === receiverId);
    return data?.senderKey;
  }
};

export const addSenderKey = (receiverId: string, senderKey: string) => {
  const isSenderKeyAvailable = checkForExistingSenderKey(receiverId);
  if (isSenderKeyAvailable) return;
  const senderKeyCollection = localStorage.getItem('senderKeyCollection');
  if (senderKeyCollection) {
    const senderKeys: { receiverId: string; senderKey: string }[] =
      JSON.parse(senderKeyCollection);
    const data = [...senderKeys, { receiverId, senderKey }];
    localStorage.setItem('senderKeyCollection', JSON.stringify(data));
  } else
    localStorage.setItem(
      'senderKeyCollection',
      JSON.stringify([{ receiverId, senderKey }])
    );
};

export const timeAgo = (date: string, lang = 'en') => {
  const currentDate: Date = new Date();
  const inputDate: Date = new Date(date);
  const elapsedMilliseconds = Number(currentDate) - Number(inputDate);

  const timeUnits = [
    {
      ms: 31536000000,
      enSingular: 'year ago',
      enPlural: 'years ago',
      ja: '年前',
    },
    {
      ms: 604800000,
      enSingular: 'week ago',
      enPlural: 'weeks ago',
      ja: '週間前',
    },
    { ms: 86400000, enSingular: 'day ago', enPlural: 'days ago', ja: '日前' },
    {
      ms: 3600000,
      enSingular: 'hour ago',
      enPlural: 'hours ago',
      ja: '時間前',
    },
    {
      ms: 60000,
      enSingular: 'minute ago',
      enPlural: 'minutes ago',
      ja: '分前',
    },
    { ms: 1000, enSingular: 'Just now', enPlural: 'Just now', ja: 'たった今' },
  ];

  for (const { ms, enSingular, enPlural, ja } of timeUnits) {
    const elapsed = Math.floor(elapsedMilliseconds / ms);
    if (elapsed > 0) {
      if (lang === 'ja') {
        return ms > 1000 ? `${elapsed}${ja}` : ja;
      } else {
        return `${ms > 1000 ? elapsed : ''} ${elapsed > 1 ? enPlural : enSingular}`;
      }
    }
  }

  return lang === 'ja' ? 'たった今' : 'Just now';
};

export const isExpired = (expiredAt: string) => {
  const currentDate = new Date();
  const expirationDate = new Date(expiredAt);

  return currentDate > expirationDate;
};

export const truncateFileName = (fileName: string, maxLength: number = 15) => {
  const fileExtension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.replace(`.${fileExtension}`, '');

  if (nameWithoutExtension.length > maxLength) {
    const truncatedName =
      nameWithoutExtension.slice(0, maxLength / 2) +
      '...' +
      nameWithoutExtension.slice(-maxLength / 2);
    return `${truncatedName}.${fileExtension}`;
  }

  return fileName;
};

export const checkReadStatus = (space: SpaceData) => {
  let hasUnread = false;

  // If latestActiveFile is null, check if all users are active
  if (space.latestActiveFile === null) {
    for (const receiver of space.receiverDetails) {
      if (receiver.activeAt === null) {
        return 'labels.waiting'; // If any receiver is not active, return 'waiting'
      }
    }
    return 'labels.active'; // All receivers are active
  }

  // Get the time for latestActiveFile
  const latestFileTime = new Date(space.latestActiveFile).getTime();

  for (const receiver of space.receiverDetails) {
    // Check if receiver is not active
    if (receiver.activeAt === null) {
      return 'labels.waiting'; // If any receiver is not active, return 'waiting'
    }

    // Get the time for receiver's activeAt
    const receiverActiveTime = new Date(receiver.activeAt).getTime();

    // Mark as unread if the receiver's active time is before the latest file time
    if (receiverActiveTime < latestFileTime) {
      hasUnread = true;
    }
  }

  // If any unread status was found, return 'unread'
  if (hasUnread) {
    return 'labels.unread';
  }

  // If no unread statuses were found and all are active, return 'active'
  return 'labels.active';
};
