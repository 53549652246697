import React, { useState } from 'react';
import { ReactComponent as DotsMenuIcon } from '../../../assets/icons/Group.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download_black.svg'; // Import your download icon
import styles from './DashboardCard.module.scss';
import { useTranslation } from 'react-i18next';

interface DashboardCardProps {
  title: string;
  readCaption: string;
  colorVariant:
    | 'red'
    | 'blue'
    | 'darkgreen'
    | 'lightgreen'
    | 'navy'
    | 'orange'
    | 'black'
    | 'brown';
  onCardClick: () => void;
  onEditClick?: (e: React.MouseEvent) => void;
  onDeleteClick?: (e: React.MouseEvent) => void;
  isReceiverCard?: boolean;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  readCaption,
  colorVariant,
  onCardClick,
  onEditClick,
  onDeleteClick,
  isReceiverCard,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      className={`${styles.card} ${styles[`${colorVariant}`]}`}
      onBlur={() => setIsMenuOpen(false)}
      tabIndex={0}
      onClick={onCardClick}
    >
      <div
        className={`${styles.verticalLine} ${styles[`card-${colorVariant}`]}`}
      ></div>
      <div className={styles.content}>
        {/* {!isReceiverCard && ( */}
        <div className={styles.header}>
          <DotsMenuIcon className={styles.dotsMenuIcon} onClick={toggleMenu} />
          {isMenuOpen && (
            <nav className={styles.menu}>
              {!isReceiverCard && (
                <span className={styles.menuItem} onClick={onEditClick}>
                  {t('buttons.edit')}
                </span>
              )}
              <span className={styles.menuItem} onClick={onDeleteClick}>
                {t('buttons.delete')}
              </span>
            </nav>
          )}
        </div>
        <div className={styles.center}>
          <h3 className={`${styles.title} ${styles[`text-${colorVariant}`]}`}>
            {title}
          </h3>
        </div>
        <div className={styles.read}>
          {isReceiverCard && <DownloadIcon className={styles.downloadIcon} />}

          <p className={styles.readCaption}>{readCaption}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
