import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import Switch from 'components/atoms/Switch';
import useModalStore from 'store/useModalStore';
import useNotificationStore from 'store/notificationStore';
import userSpaceStore from 'store/userSpaceStore';
import useAuthStore from 'store/authStore';
import useAlert from 'store/useAlert';
import { getErrorMessage } from 'utils/helper';
import Logo from '../../../assets/icons/logo.svg';
import { ReactComponent as ReceivedNotificationIcon } from '../../../assets/icons/notification.svg';
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notification-off.svg';
import { ReactComponent as BurgerMenuIcon } from '../../../assets/icons/burger.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import styles from './NavBar.module.scss';
import useNavigationActions from 'hooks/useNavigationAction';
import useSubscriptionPlan from 'hooks/profile/useSubscriptionPlan';
import { getCookies } from 'utils/cookies';
import tooltip from 'components/atoms/ToolTip';

const ToolTipNotificationIcon = tooltip(NotificationIcon, {
  className: {
    container: 'react-tooltip',
    content: 'react-tooltip__content'
  }
});


interface MenuItem {
  name: string;
  onClick: () => void;
}

interface HeaderNavbarProps {
  isReceiverMenu?: boolean;
}

const HeaderNavbar: React.FC<HeaderNavbarProps> = ({
  isReceiverMenu = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setOpenModal } = useModalStore();
  const { locale, setLocale } = useAuthStore((state) => state);
  const currentPath = window.location.pathname;
  const isNotification = useNotificationStore((state) => state.isNotification);
  const { spaceSizeData, userSpacesData, spaceReceivers } = userSpaceStore(
    (state) => state
  );
  const setAlert = useAlert((state) => state.setAlert);
  const { subscriptionPlan } = useSubscriptionPlan();

  const isSkipUploadModal = localStorage.getItem('isSkipUploadModal');
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const isAuthenticated = getCookies('accessToken');

  const {
    onEditAccount,
    onChangePassword,
    openSecretCode,
    onLogout,
    onEditSpace,
    openResetSecretCode,
    onOpenSubscriptionPlans,
    onOpenPaymentCards,
    onChangeLanguage,
  } = useNavigationActions();

  const isSenderSpaceScreen = window.location.pathname.includes('sender-space');

  const menuItems = [
    { name: t('navOptions.account'), onClick: onEditAccount },
    { name: t('navOptions.myCards'), onClick: onOpenPaymentCards },
    { name: t('navOptions.changePassword'), onClick: onChangePassword },
    { name: t('navOptions.secretCode'), onClick: openSecretCode },
    { name: t('navOptions.resetSecretCode'), onClick: openResetSecretCode },
    { name: t('navOptions.subscription'), onClick: onOpenSubscriptionPlans },
    { name: t('navOptions.language'), onClick: onChangeLanguage },
    { name: t('navOptions.logout'), onClick: onLogout },
  ];

  if (isSenderSpaceScreen) {
    menuItems.splice(1, 0, {
      name: t('navOptions.spaceSettings'),
      onClick: () => onEditSpace(),
    });
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleBurgerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleCreateSpace = () => {
    if (subscriptionPlan) {
      const currentPlan = subscriptionPlan.result.filter(
        (plan) => plan.default
      );
      if (
        userSpacesData?.docs &&
        userSpacesData?.docs?.length >= currentPlan[0]?.spaceLimit
      ) {
        setAlert(t(getErrorMessage('1070')));
        return;
      }
    } else {
      if (
        spaceSizeData?.planType === 'free' &&
        userSpacesData?.docs &&
        userSpacesData?.docs?.length >= 3
      ) {
        setAlert(t(getErrorMessage('1070')));
        return;
      }
    }
    if (isSkipUploadModal === 'true') {
      setOpenModal('createSpace');
    } else {
      setOpenModal('addSpaceConfirmModal');
    }
  };

  const handleAddReceiver = () => {
    if (subscriptionPlan) {
      const currentPlan = subscriptionPlan.result.filter(
        (plan) => plan.default
      );
      if (
        spaceReceivers &&
        spaceReceivers?.length >= currentPlan[0]?.receiverLimit
      ) {
        setAlert(t(getErrorMessage('1071')));
        return;
      }
    } else {
      if (
        spaceSizeData?.planType === 'free' &&
        spaceReceivers &&
        spaceReceivers?.length >= 1
      ) {
        setAlert(t(getErrorMessage('1071')));
        return;
      }
    }
    setOpenModal('addReceiver');
  };

  const handleLanguageToggle = () => {
    localStorage.setItem('locale', locale === 'en' ? 'ja' : 'en');
    setLocale(locale === 'en' ? 'ja' : 'en');
  };

  const handleMenuItemClick = (onClick: () => void) => {
    setShowMenu(false);
    onClick();
  };

  useEffect(() => {
    setNotification(isNotification);
    if (isNotification) setOpenModal('notificationModal');
  }, [isNotification]);

  return (
    <nav className={styles.header}>
      <img
        src={Logo}
        alt="Logo"
        className={styles.logo}
        onClick={() => navigate('/')}
      />
      <div className={styles.iconWrapper} ref={menuRef}>
        {isReceiverMenu ? (
          <div className={styles.switchContainer}>
            <Switch value={locale} onToggle={handleLanguageToggle} />
            <div
              className={styles.accountText}
              onClick={() => navigate('/login')}
            >
              {t('navOptions.accountLogin')}
            </div>
          </div>
        ) : (
          isAuthenticated && (
            <>
              <div
                className={styles.icon}
                onClick={() => setOpenModal('notificationModal')}
              >
                {showNotification ? (
                  <ReceivedNotificationIcon />
                ) : (
                  <ToolTipNotificationIcon title={t("labels.notification")} />
                )}
              </div>
              {!currentPath.includes('temp-space') && (
                <>
                  {currentPath === '/' && (
                    <Button
                      className="addButton"
                      icon={<PlusIcon />}
                      iconPosition="left"
                      onClick={() => handleCreateSpace()}
                    >
                      {t('navOptions.newSpace')}
                    </Button>
                  )}
                  {currentPath.includes('sender-space') && (
                    <Button
                      className="addButton"
                      icon={<PlusIcon />}
                      iconPosition="left"
                      onClick={() => handleAddReceiver()}
                    >
                      {t('navOptions.addReceiver')}
                    </Button>
                  )}
                </>
              )}
              <div className={styles.icon} onClick={handleBurgerClick}>
                {showMenu ? <CloseIcon /> : <BurgerMenuIcon />}
              </div>
            </>
          )
        )}
      </div>
      {showMenu && (
        <div className={styles.menuItems}>
          {menuItems.map((item, index) => (
            <div
              key={index}
              onClick={() => handleMenuItemClick(item.onClick)}
              className={styles.value}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </nav>
  );
};

export default HeaderNavbar;
