import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardCard from 'components/molecules/DashboardCard';
import useUserSpaces from 'hooks/space/useUserSpaces';
import useSpace from 'hooks/space/useSpace';
import useReceiverSpace from 'hooks/receiver/useReceiverSpace';
import { useSecretCode } from 'hooks/encryption/useSecretCode';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';
import useAuthStore from 'store/authStore';
import useModalStore from 'store/useModalStore';
import userSpaceStore from 'store/userSpaceStore';
import useAlert from 'store/useAlert';
import {
  checkReadStatus,
  getErrorMessage,
  getSuccessMessage,
} from 'utils/helper';
import styles from './Dashboard.module.scss';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import DashboardImg from '../../assets/img/dashboard.png';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [spaceList, setSpaceList] = useState<SpaceData[]>();
  const [spaceId, setSpaceId] = useState('');

  const { isNewUser, setNewUser, secretKey } = useAuthStore((state) => state);
  const { setOpenModal, setModalProps, closeModal, setConfirmModalProps } =
    useModalStore();
  const { userSpaces, isLoading } = useUserSpaces(0);
  const { receiverSpaceData, onDeleteReceiverSpace, getReceiverSpaceData } =
    useReceiverSpace();
  const { getSecretCode } = useSecretCode();
  const { setUserSpaceList, setCurrentSpaceId, setSpaceSizeData } =
    userSpaceStore((state) => state);
  const { deleteSpace } = useSpace(spaceId);
  const setAlert = useAlert((state) => state.setAlert);
  const { spaceSizeData, getStorageInfo, error } = useSpaceFiles();

  useEffect(() => {
    if (isNewUser) {
      setOpenModal('secretCode');
      const secretCode = getSecretCode(secretKey);
      setModalProps({
        secretCode: secretCode,
        onClose: () => {
          closeModal();
          setOpenModal('newUserFileUpload');
        },
      });
      setNewUser(false);
    }
  }, [isNewUser]);

  useEffect(() => {
    if (userSpaces && userSpaces.success) {
      const { docs } = userSpaces.data;
      setSpaceList(docs);
      setUserSpaceList(userSpaces.data);
    }
  }, [userSpaces]);

  const onEditSpace = (spaceId: string) => {
    setSpaceId(spaceId);
    setCurrentSpaceId(spaceId);
    setOpenModal('editSpace');
  };

  const onDeleteSpace = () => {
    deleteSpace()
      .then((data) => {
        if (data.success) {
          setCurrentSpaceId('');
          setAlert(t(getSuccessMessage('default')), 'success');
          closeModal();
        }
      })
      .catch((error: ResponseError) => {
        setAlert(t(getErrorMessage(error.subStatusCode)));
      });
  };

  const onDeleteSpaceClick = (spaceId: string) => {
    setCurrentSpaceId(spaceId);
    setSpaceId(spaceId);
    setOpenModal('confirmModal', 'confirm');
    setConfirmModalProps({
      title: t('titles.deleteSpace'),
      content: t('labels.deleteSpaceConfirmation'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => onDeleteSpace(),
      },
      isOpen: true,
    });
  };

  const onDeleteReceiverSpaceClick = (receiverId: string) => {
    setOpenModal('confirmModal', 'confirm');
    setConfirmModalProps({
      title: t('titles.deleteSpace'),
      content: t('labels.deleteSpaceConfirmation'),
      cancel: {
        children: t('buttons.no'),
        onClick: () => closeModal(),
      },
      confirm: {
        children: t('buttons.yes'),
        onClick: () => {
          onDeleteReceiverSpace(receiverId).then(() => {
            getReceiverSpaceData();
            setAlert(t(getSuccessMessage('default')), 'success');
            closeModal();
          });
        },
      },
      isOpen: true,
    });
  };

  const onSpaceDetailClick = (spaceId: string) => {
    setCurrentSpaceId(spaceId);
    navigate(`/sender-space/${spaceId}`);
  };

  useEffect(() => {
    if (spaceSizeData?.success) {
      setSpaceSizeData(spaceSizeData.result[0]);
    } else getStorageInfo();
  }, [spaceSizeData]);

  useEffect(() => {
    if (error?.subStatusCode) setAlert(t(getErrorMessage(error.subStatusCode)));
  }, [error]);

  const items = [
    { label: 'labels.CreateSharedSpaceForEachUser', color: '' },
    { label: 'labels.UploadFilesSharedSpace', color: '' },
    {
      label: 'labels.SendTheEncryptedLink',
      sublabel1: 'labels.whenTheSharerConnectsTo',
      sublabel2: 'labels.e2eeFileSharingBeginsWhen',
      color: '',
    },
    { label: 'labels.IfYouWantToShareAgain', color: 'green' },
  ];

  const renderTextWithIcon = (text: string, color: string) => {
    const parts = text.split(/\(([^)]+)\)/);
    return (
      <>
        {parts.map((part, index) =>
          index % 2 === 0 ? (
            <span key={index}>{part}</span>
          ) : part.trim().length === 0 ? (
            <span
              className={`${styles.renderTextWithIconCopyIconWrapper} ${color ? styles.greenIcon : ''} `}
              key={index}
            >
              &#40;
              <CopyIcon />
              &#41;
            </span>
          ) : (
            <span key={index}>{` (${part})`}</span>
          )
        )}
      </>
    );
  };

  return (
    <div className={styles.dashBoardWrapper}>
      <div className={styles.dashBoard}>
        {spaceList?.map(
          (space) =>
            !space.isTemporary && (
              <DashboardCard
                key={space._id}
                title={
                  space?.spaceName
                    ? space.spaceName
                    : space.alternativeReceiverName
                }
                readCaption={t(checkReadStatus(space))}
                colorVariant={space.spaceColor}
                onCardClick={() => onSpaceDetailClick(space.spaceId)}
                onEditClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onEditSpace(space.spaceId);
                }}
                onDeleteClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onDeleteSpaceClick(space.spaceId);
                }}
              />
            )
        )}
        {receiverSpaceData?.result?.map((space) => (
          <DashboardCard
            key={space._id}
            title={space.receiverName}
            readCaption={''}
            colorVariant={space.spaceColor}
            onCardClick={() => navigate(`/receiver-space/${space.receiverId}`)}
            isReceiverCard={true}
            onDeleteClick={() => onDeleteReceiverSpaceClick(space.receiverId)}
          />
        ))}
        {isLoading ? (
          <div className={styles.textCenter}>Loading...</div>
        ) : (
          !spaceList?.length &&
          !receiverSpaceData?.result?.length && <div>{t('labels.noSpace')}</div>
        )}
      </div>
      <div className={styles.boxContent}>
        {/* <h1>{t('labels.e2eeFileSharing')}</h1>
        <ol type="1" className={styles.orderList}>
          {items.map((item, index) => {
            return (
              <li key={index}>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  {renderTextWithIcon(t(item.label), item?.color)}
                </p>
                {item.sublabel1 && (
                  <p>
                    {t(item.sublabel1)}{' '}
                    <span style={{ color: '#33adff' }}>
                      {t(item.sublabel2)}
                    </span>
                  </p>
                )}
              </li>
            );
          })}
        </ol>
        <span className={styles.image}>
          <img src={SpaceImage} alt="" />
        </span> */}
        <img src={DashboardImg} alt="dashboard-img" />
      </div>
    </div>
  );
};

export default Dashboard;
