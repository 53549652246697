import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import userSpaceStore from 'store/userSpaceStore';
import useAuthStore from 'store/authStore';
import { getCookies } from 'utils/cookies';

const useReceiverSpace = (spaceId?: string) => {
  const { put, get, del } = useAPI();
  const accessToken = getCookies('accessToken');
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const receiverId = userSpaceStore((state) => state.receiverId);
  const bulkId = userSpaceStore((state) => state.bulkId);

  const {
    data: receiverSpaceData,
    error,
    isLoading,
    mutate: getReceiverSpaceData,
  } = useSWR<ReceiverSpaceListResponse>(
    accessToken ? API_ROUTES.getReceiverSpaceList : '',
    get,
    {
      revalidateOnFocus: false,
      errorRetryCount: 3,
    }
  );

  const {
    data: receiverFiles,
    error: receiverError,
    isLoading: receiverLoading,
    mutate: getReceiverFiles,
  } = useSWR<any>(
    receiverId && accessToken
      ? API_ROUTES.getReceiverFilesById(receiverId)
      : '',
    get,
    {
      revalidateOnFocus: false,
      // revalidateIfStale: false,
      errorRetryCount: 2,
    }
  );

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<CheckSpaceAccessPayload> }
  ) => {
    const response: ReceiverSpaceResponse = await put(url, arg);
    return response;
  };

  const {
    trigger: checkValidAccessForUser,
    isMutating: checkingAccess,
    error: checkError,
  } = useSWRMutation(
    receiverId ? API_ROUTES.checkValidAccessToSpace(receiverId) : null,
    sendUpdateRequest
  );

  const {
    trigger: checkValidAccessToGuest,
    isMutating: checkingGuestAccess,
    error: checkGuestError,
  } = useSWRMutation(
    receiverId ? API_ROUTES.checkValidAccessToGuest(receiverId) : null,
    sendUpdateRequest
  );

  const {
    trigger: checkValidAccessShareSpace,
    isMutating: checkingSpaceAccess,
    error: checkSpaceError,
  } = useSWRMutation(
    spaceId ? API_ROUTES.checkValidAccessShareSpace(spaceId) : null,
    sendUpdateRequest
  );

  const {
    trigger: checkValidGuestAccessShareSpace,
    isMutating: checkingGuestSpaceAccess,
    error: checkGuestSpaceError,
  } = useSWRMutation(
    spaceId ? API_ROUTES.checkValidGuestAccessShareSpace(spaceId) : null,
    sendUpdateRequest
  );

  const {
    trigger: checkValidAccessShareFile,
    isMutating: checkingFileAccess,
    error: checkFileError,
  } = useSWRMutation(
    bulkId ? API_ROUTES.checkValidAccessShareFile(bulkId) : null,
    sendUpdateRequest
  );

  const {
    trigger: checkValidGuestAccessShareFile,
    isMutating: checkingGuestFileAccess,
    error: checkGuestFileError,
  } = useSWRMutation(
    bulkId ? API_ROUTES.checkValidGuestAccessShareFile(bulkId) : null,
    sendUpdateRequest
  );

  const onDeleteReceiverSpace = async (receiverId: string) => {
    try {
      const response = await del(API_ROUTES.deleteSenderSpace(receiverId));
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    getReceiverSpaceData,
    receiverSpaceData,
    receiverFiles,
    getReceiverFiles,
    checkValidAccessForUser,
    checkValidAccessToGuest,
    checkValidAccessShareSpace,
    checkValidGuestAccessShareSpace,
    checkValidAccessShareFile,
    checkValidGuestAccessShareFile,
    onDeleteReceiverSpace,
    isLoading:
      isLoading ||
      checkingAccess ||
      checkingGuestAccess ||
      checkingSpaceAccess ||
      checkingGuestSpaceAccess ||
      checkingFileAccess ||
      checkingGuestFileAccess,
    error:
      error ||
      checkError ||
      checkGuestError ||
      checkSpaceError ||
      checkGuestSpaceError ||
      checkFileError ||
      checkGuestFileError,
    receiverError,
    receiverLoading,
  };
};

export default useReceiverSpace;
