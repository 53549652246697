import React, { useState } from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/atoms/CheckBox';
import useModalStore from 'store/useModalStore';
import styles from './TransferReadmeModal.module.scss';

const TransferReadmeModal = () => {
  const { t } = useTranslation();
  const [showReadme, setShowReadme] = useState(false);
  const { closeModal } = useModalStore();
  const steps: any = t('common.spaceReadme.steps', { returnObjects: true });

  return (
    <div className={styles.transferModal}>
      {/* <div className="transferModal"></div> */}
      <div className={styles.titleWrapper}>
        <h1>{t('common.spaceReadme.title')}</h1>
      </div>
      <div className={styles.description}>
        {steps?.map((step: any, index: number) => (
          <React.Fragment key={index}>
            <p>{step.title}</p>
            <span className={styles.content}>{step.description}</span>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.tips}>
        <h3>{t('common.spaceReadme.tips')}</h3>
        <span>{t('common.spaceReadme.tip1')}</span>
        <span>{t('common.spaceReadme.tip2')}</span>
      </div>
      <div className={styles.checkboxWrapper}>
        <CheckBox
          label={t('common.spaceReadme.doNotShowReadme')}
          checked={showReadme}
          onChange={(checked) => {
            setShowReadme(checked);
            localStorage.setItem(
              'isDisplaySpaceReadme',
              checked ? 'true' : 'false'
            );
          }}
        />
      </div>
      <div className={styles.btnWrapper}>
      <Button className="close" onClick={closeModal}>
        {t('buttons.close')}
      </Button>
      </div>
    </div>
  );
};

export default TransferReadmeModal;
