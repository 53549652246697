import CryptoJs from 'crypto-js';
import AES256 from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import base64 from 'crypto-js/enc-base64';
import CryptoJSSHA from 'crypto-js/sha256';
import Randomstring from 'randomstring';

export const generateRandomSecretCode = () => {
  return Randomstring.generate({
    length: 6,
    charset: 'alphanumeric',
  });
};

export const generateFileKey = (secretCode: string, secretSalt: string) => {
  return CryptoJSSHA(secretCode + secretSalt).toString();
};

export const generateLocalSecretAuth = (userId: string, fileKey: string) => {
  const key = base64.parse(fileKey);
  const iv = base64.parse(fileKey);
  return AES256.encrypt(userId, key, { iv: iv }).toString();
};

export const encryptSecretCode = (secretCode: string, secretKey: string) => {
  return AES256.encrypt(secretCode, secretKey).toString();
};

export const decryptSecretCode = (encryptedCode: string, secretKey: string) => {
  return AES256.decrypt(encryptedCode, secretKey, {}).toString(Utf8);
};

export const generateSenderKey = (fileKey: string, transferKey: string) => {
  const salt = CryptoJs.enc.Hex.parse(transferKey);

  const key = CryptoJs.PBKDF2(transferKey, salt, {
    keySize: 256 / 32,
    iterations: 100,
  });

  const iv = CryptoJs.enc.Hex.parse(transferKey);

  const encrypted = CryptoJs.AES.encrypt(fileKey, key, {
    iv: iv,
    padding: CryptoJs.pad.Pkcs7,
    mode: CryptoJs.mode.CBC,
  });
  const transitMessage = encrypted.toString();
  return transitMessage;
};

export const generateSharedKey = (senderKey: string) => {
  const wordArray = Utf8.parse(senderKey);
  return base64.stringify(wordArray).toString();
};

export const encryptSenderKey = (senderKey: string, fileKey: string) => {
  return AES256.encrypt(senderKey, fileKey).toString();
};

export const decryptSenderKey = (encryptSenderKey: string, fileKey: string) => {
  return AES256.decrypt(encryptSenderKey, fileKey).toString(Utf8);
};

export const decryptSharedKeyByBase64 = (sharedKey: string) => {
  return base64.parse(sharedKey).toString(Utf8);
};

export async function encryptFile(file: File, fileKey: string): Promise<File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const encrypted = AES256.encrypt(
          e.target?.result as string,
          fileKey
        ).toString();
        const encryptedFile = new File([encrypted], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        resolve(encryptedFile);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsDataURL(file);
  });
}

export async function decryptFile(file: File, fileKey: string) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const decrypted = CryptoJs.AES.decrypt(
          e?.target?.result?.toString() as string,
          fileKey
        ).toString(CryptoJs.enc.Latin1);
        resolve(decrypted);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsText(file);
  });
}

export const getTerminalId = () => {
  const terminalId = localStorage.getItem('terminalId');
  if (terminalId) return terminalId;
  else {
    const newTerminalId = Randomstring.generate(32);
    localStorage.setItem('terminalId', newTerminalId);
    return newTerminalId;
  }
};

export const decryptFileKeyFromSenderKey = (
  senderKey: string,
  transferKey: string
) => {
  var salt = CryptoJs.enc.Hex.parse(transferKey);
  var iv = CryptoJs.enc.Hex.parse(transferKey);
  var encrypted = senderKey;

  var key = CryptoJs.PBKDF2(transferKey, salt, {
    keySize: 256 / 32,
    iterations: 100,
  });

  var decrypted = CryptoJs.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJs.pad.Pkcs7,
    mode: CryptoJs.mode.CBC,
  });
  return decrypted.toString(Utf8);
};
