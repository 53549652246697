import React, { useEffect, useState } from 'react';
import styles from './ReceiverSpace.module.scss';
import HeaderNavbar from 'components/molecules/NavBar';
import { useParams } from 'react-router-dom';
import useAuthStore from 'store/authStore';
import userSpaceStore from 'store/userSpaceStore';
import { decryptSharedKeyByBase64, getTerminalId } from 'utils/encryptionUtils';
import { useCryptoLink } from 'hooks/encryption/useCryptoLink';
import ReceiverSidebar from 'components/organism/SideBar/ReceiverSidebar';
import FileListLayout from 'components/organism/FileListLayout';
import { useTranslation } from 'react-i18next';
import {
  addSenderKey,
  checkForExistingSenderKey,
  getErrorMessage,
} from 'utils/helper';
import { io } from 'socket.io-client';
import { getCookies } from 'utils/cookies';
import useAlert from 'store/useAlert';
import { useAPI } from 'hooks/useAPI';
import API_ROUTES from 'services/apiRoutes';
import Modal from 'components/atoms/Modal';
import useModalStore from 'store/useModalStore';
import Randomstring from 'randomstring';

const sessionid = Math.random().toString();

const ReceiverSpace = () => {
  const { spaceId, receiverId, sharedKey, sharedFilesLinkId } = useParams<{
    receiverId: string;
    sharedKey: string;
    spaceId: string;
    sharedFilesLinkId: string;
  }>();
  const { t } = useTranslation();
  const [isGuest, setIsGuest] = useState(false);
  const [decryptedSpaceId, setSpaceId] = useState('');
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const accessToken = getCookies('accessToken');
  const {
    setReceiverId,
    setBulkId,
    receiverData,
    setReceiverData,
    receiverId: storeReceiverId,
  } = userSpaceStore((state) => state);
  const [isWarningModal, setWarningModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const setAlert = useAlert((state) => state.setAlert);
  const { put } = useAPI();
  const { getEncryptedSenderKey } = useCryptoLink();
  const { setOpenModal } = useModalStore();

  useEffect(() => {
    if (receiverId) {
      setReceiverId(receiverId);
      localStorage.setItem('receiverId', receiverId);
      receiverSocket();
    }
  }, [receiverId]);

  const sendUserVerificationRequest = async (
    url: string,
    arg: Partial<CheckSpaceAccessPayload>
  ) => {
    const response: ReceiverSpaceResponse = await put(url, arg);
    return response;
  };

  useEffect(() => {
    if (!accessToken) {
      setIsGuest(true);
      localStorage.setItem('isGuest', 'true');
    }
  }, [isAuthenticated, isGuest, accessToken]);

  useEffect(() => {
    if (errorText) {
      setWarningModal(true);
    }
  }, [errorText]);

  const receiverSocket = () => {
    if (receiverId) {
      const socket = io(
        process.env.REACT_APP_RECEIVER_NOTIFICATION_URL as string,
        {
          extraHeaders: {
            receiverId: receiverId as string,
            sessionid: sessionid,
          },
        }
      );
      if (socket) {
        console.log('******STARTING RECEIVER SOCKET********');
        socket
          .off('receiverAccessPermission')
          .on('receiverAccessPermission', (data: ReceiverSocketMessage) => {
            console.log('RECEIVER DATA ====', data);
            if (data.message === 'receiverDelete') {
              if (isGuest) onVerifyGuestUser();
              else onVerifyAuthUser();
            }
            if (data?.status === 'active') {
              if (!isGuest) onVerifyAuthUser();
              else onVerifyGuestUser();
              setWarningModal(false);
            }
          });
        socket.on('disconnect', (reason) => {
          socket.disconnect();
          // if (reason === 'io server disconnect')
          //   setOpenModal('duplicateTabModal');
          console.log('Disconnected from the server ==', reason);
        });
      }
    }
  };

  const onVerifyAuthUser = () => {
    if (receiverId && sharedKey) {
      const senderKey = decryptSharedKeyByBase64(sharedKey);
      const encryptedSenderKey = getEncryptedSenderKey(senderKey);
      localStorage.setItem('senderKey', senderKey);
      addSenderKey(receiverId, senderKey);

      sendUserVerificationRequest(
        API_ROUTES.checkValidAccessToSpace(receiverId),
        { encryptedSenderKey, senderKey }
      )
        .then((response) => {
          if (response.success) {
            console.log('success=', encryptedSenderKey, senderKey);
            setReceiverData({ ...response.result, encryptedSenderKey });
          }
        })
        .catch((e: ResponseError) => {
          if (e.subStatusCode === '1057') {
            window.location.href = `/sender-space/${e.errorMessage}`;
            return;
          }
          setErrorText(t(getErrorMessage(e.subStatusCode)));
          console.log(e);
        });
    } else if (spaceId) {
      const sharedKey = Randomstring.generate();
      // if (!senderKey) {
      //   console.log('FROM HERE ===152', senderKey);
      //   setAlert(t(getErrorMessage('1039')));
      //   return;
      // }
      // const encryptedSenderKey = getEncryptedSenderKey(senderKey);
      sendUserVerificationRequest(
        API_ROUTES.checkValidAccessShareSpace(spaceId),
        {
          // encryptedSenderKey,
          sharedKey,
        }
      )
        .then((response) => {
          if (response.success) {
            setReceiverData(response.result);
          }
        })
        .catch((e: ResponseError) => {
          if (e.subStatusCode === '1057') {
            window.location.href = `/sender-space/${e.errorMessage}`;
            return;
          }
          setAlert(t(getErrorMessage(e.subStatusCode)));
          console.log(e);
        });
    } else if (sharedFilesLinkId && sharedKey) {
      const bulkId = decryptSharedKeyByBase64(sharedFilesLinkId);
      setBulkId(bulkId);
      const senderKey = decryptSharedKeyByBase64(sharedKey);
      sendUserVerificationRequest(
        API_ROUTES.checkValidAccessShareFile(bulkId),
        { sharedKey: senderKey }
      )
        .then((response) => {
          if (response.success) {
            setReceiverData(response.result);
          }
        })
        .catch((e: ResponseError) => {
          if (e.subStatusCode === '1057') {
            window.location.href = `/sender-space/${e.errorMessage}`;
            return;
          }
          setAlert(t(getErrorMessage(e.subStatusCode)));
          console.log(e);
        });
    }
  };

  const onVerifyGuestUser = () => {
    if (receiverId && sharedKey) {
      const senderKey = decryptSharedKeyByBase64(sharedKey);
      localStorage.setItem('senderKey', senderKey);
      addSenderKey(receiverId, senderKey);

      const terminalId = getTerminalId();
      sendUserVerificationRequest(
        API_ROUTES.checkValidAccessToGuest(receiverId),
        { terminalId, senderKey }
      )
        .then((response) => {
          if (response.success) {
            setReceiverData(response.result);
          }
        })
        .catch((e: ResponseError) => {
          if (e.subStatusCode === '1057') {
            window.location.href = `/sender-space/${e.errorMessage}`;
            return;
          }
          setErrorText(t(getErrorMessage(e.subStatusCode)));
          if (e.subStatusCode === '1040') receiverSocket();
          console.log(e);
        });
    } else if (spaceId) {
      const sharedKey = Randomstring.generate();
      // checkForExistingSenderKey(spaceId);
      // if (!senderKey) {
      //   setAlert(t(getErrorMessage('1039')));
      //   return;
      // }
      const terminalId = getTerminalId();
      sendUserVerificationRequest(
        API_ROUTES.checkValidGuestAccessShareSpace(spaceId),
        {
          terminalId,
          sharedKey,
        }
      )
        .then((response) => {
          if (response.success) {
            setReceiverData(response.result);
          }
        })
        .catch((e: ResponseError) => {
          if (e.subStatusCode === '1057') {
            window.location.href = `/sender-space/${e.errorMessage}`;
            return;
          }
          setErrorText(t(getErrorMessage(e.subStatusCode)));
          console.log(e);
        });
    } else if (sharedFilesLinkId && sharedKey) {
      const bulkId = decryptSharedKeyByBase64(sharedFilesLinkId);

      setBulkId(bulkId);
      const senderKey = decryptSharedKeyByBase64(sharedKey);
      localStorage.setItem('senderKey', senderKey);

      const terminalId = getTerminalId();
      sendUserVerificationRequest(
        API_ROUTES.checkValidGuestAccessShareFile(bulkId),
        { terminalId, sharedKey: senderKey }
      )
        .then((response) => {
          if (response.success) {
            setReceiverData(response.result);
          }
        })
        .catch((e: ResponseError) => {
          if (e.subStatusCode === '1057') {
            window.location.href = `/sender-space/${e.errorMessage}`;
            return;
          }
          setErrorText(t(getErrorMessage(e.subStatusCode)));
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (isGuest) onVerifyGuestUser();
    else if (accessToken) onVerifyAuthUser();
  }, [isGuest, receiverId, accessToken]);

  return (
    <>
      <HeaderNavbar isReceiverMenu={!isAuthenticated && !accessToken && true} />
      <div className={styles.wrapper}>
        <ReceiverSidebar ownerName={receiverData?.OwnerName || ''} />
        <div className={styles.cover}>
          <div className={styles.top}>
            <h1>
              {
                // `${receiverData?.OwnerName || ''}'s Space`}
                receiverData?.OwnerName
                  ? t('labels.spaceOfOwner', { name: receiverData?.OwnerName })
                  : receiverData?.spaceName
              }
            </h1>
          </div>
          <FileListLayout
            isReceiver={true}
            spaceFiles={receiverData?.getFile || []}
          />
        </div>
      </div>
      <Modal isOpen={isWarningModal} onClose={() => {}}>
        <p>{errorText}</p>
      </Modal>
    </>
  );
};

export default ReceiverSpace;
